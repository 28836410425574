import React, { useEffect, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllCounter, getAllQueueMachine } from "../../actions";
import Button from "../../components/Button";
import Header from "../../components/Header";
import Modal from "../../components/Modal";
import Select from "../../components/Select";
import "./index.css";

const Home = () => {
  const [counterId, setCounterId] = useState("");
  const [detailData, setDetailData] = useState(null);
  const [path, setPath] = useState(null);
  const [modal, setModal] = useState(false);

  const queueMachine = useSelector((state) => state.queueMachine);
  const counter = useSelector((state) => state.counter);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllQueueMachine()).catch((err) => {
      console.log(err);
    });

    dispatch(getAllCounter()).catch((err) => {
      console.log(err);
    });
  }, []);

  const renderModal = () => {
    return (
      detailData && (
        <Modal
          size="md"
          show={modal}
          handleClose={() => setModal(false)}
          title="Pilih Loket"
        >
          <Row className="m-0 mb-5">
            <Col>
              <Select
                value={counterId}
                onChange={(e) => setCounterId(e.target.value)}
                defaultOption
                defaultOptionTitle="Pilih Loket"
                data={detailData}
              />
            </Col>
          </Row>

          <Row className="m-0 mb-2">
            <Col>
              <Button
                title="Masuk"
                onClick={() => navigate(`${path}/${counterId}`)}
              />
            </Col>
          </Row>

          <Row className="m-0">
            <Col>
              <Button title="Batal" onClick={() => setModal(false)} />
            </Col>
          </Row>
        </Modal>
      )
    );
  };

  const handleShowModal = async (data, path) => {
    let newData = [];

    await data.map((data) => {
      newData.push({
        value: data._id,
        title: data.name,
      });
    });

    setDetailData(newData);
    setPath(path);
    setModal(true);
  };

  return (
    <>
      <Header />

      <div className="large-container">
        <div className="large-inner-container">
          <Row>
            <Col md={4}>
              <Row className="m-0 mb-3">
                <Col>
                  <span className="fw-bold fs-20">Layar Pasien</span>
                </Col>
              </Row>

              <Row className="m-0 mb-2">
                {queueMachine.allQueueMachine?.length &&
                  queueMachine.allQueueMachine.map((data, index) => (
                    <Col
                      className="mb-2 d-flex flex-column text-center"
                      xs={6}
                      sm={4}
                      key={index}
                    >
                      <div
                        className="mb-1 home-card cursor-pointer"
                        onClick={() => navigate(`/patient-display/${data._id}`)}
                      >
                        <Image
                          src="/img/icon/icon-home-patient.svg"
                          height={"50px"}
                        />
                      </div>

                      <div>
                        <span className="fw-bold">{data.name}</span>
                      </div>
                    </Col>
                  ))}
              </Row>
            </Col>
            <Col md={4}>
              <Row className="m-0 mb-3">
                <Col>
                  <span className="fw-bold fs-20">Layar TV</span>
                </Col>
              </Row>

              <Row className="m-0 mb-2">
                {queueMachine.allQueueMachine?.length &&
                  queueMachine.allQueueMachine.map((data, index) => (
                    <Col
                      className="mb-2 d-flex flex-column text-center"
                      xs={6}
                      sm={4}
                      key={index}
                    >
                      <div
                        className="mb-1 home-card cursor-pointer"
                        onClick={() => navigate(`/queue-display/${data._id}`)}
                      >
                        <Image src="/img/icon/icon-home-tv.svg" height={"50px"} />
                      </div>

                      <div>
                        <span className="fw-bold">{data.name}</span>
                      </div>
                    </Col>
                  ))}
              </Row>
            </Col>
            <Col md={4}>
              <Row className="m-0 mb-3">
                <Col>
                  <span className="fw-bold fs-20">Layar Loket</span>
                </Col>
              </Row>

              <Row className="m-0 mb-2">
                {queueMachine.allQueueMachine?.length &&
                  queueMachine.allQueueMachine.map((data, index) => (
                    <Col
                      className="mb-2 d-flex flex-column text-center"
                      xs={6}
                      sm={4}
                      key={index}
                    >
                      <div
                        className="mb-1 home-card cursor-pointer"
                        onClick={() =>
                          handleShowModal(
                            counter.counter,
                            `/admin-display/${data._id}`
                          )
                        }
                      >
                        <Image
                          src="/img/icon/icon-home-counter.svg"
                          height={"50px"}
                        />
                      </div>

                      <div>
                        <span className="fw-bold">{data.name}</span>
                      </div>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>
          
        </div>
        <div className="text-center mt-2">
          <span>Aido Health All Right Reserved </span>
        </div>
      </div>
      {renderModal()}
    </>
  );
};

export default Home;
