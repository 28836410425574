import axios from "../helpers/axios";
import { queueProcessConstants } from "./constants";

export const getAllQueueProcess = (id) => {
  return async (dispatch) => {
    dispatch({ type: queueProcessConstants.GET_ALL_QUEUE_PROCESS_REQUEST });

    const res = await axios.get(`/api/counter-last-call/${id}`).catch((err) => {
      return dispatch({
        type: queueProcessConstants.GET_ALL_QUEUE_PROCESS_FAILURE,
        payload: err.response.data,
      });
    });

    if (res.status === 200) {
      dispatch({
        type: queueProcessConstants.GET_ALL_QUEUE_PROCESS_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};

export const getQueueProcess = (machineId, counterId) => {
  return async (dispatch) => {
    dispatch({ type: queueProcessConstants.GET_QUEUE_PROCESS_REQUEST });

    const res = await axios
      .get(`/api/counter-last-call/${machineId}/${counterId}`)
      .catch((err) => {
        return dispatch({
          type: queueProcessConstants.GET_QUEUE_PROCESS_FAILURE,
          payload: err.response.data,
        });
      });

    if (res.status === 200) {
      dispatch({
        type: queueProcessConstants.GET_QUEUE_PROCESS_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};

export const nextCallQueueProcess = (body) => {
  return async (dispatch) => {
    dispatch({ type: queueProcessConstants.NEXT_CALL_QUEUE_PROCESS_REQUEST });

    const res = await axios
      .post("/api/queue-process/next-call", body)
      .catch((err) => {
        return dispatch({
          type: queueProcessConstants.NEXT_CALL_QUEUE_PROCESS_FAILURE,
          payload: err.response.data,
        });
      });

    if (res.status === 200) {
      dispatch({
        type: queueProcessConstants.NEXT_CALL_QUEUE_PROCESS_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};

export const createQueueProcess = (body) => {
  return async (dispatch) => {
    dispatch({ type: queueProcessConstants.CREATE_QUEUE_PROCESS_REQUEST });

    const res = await axios.post("/api/queue-process/", body).catch((err) => {
      return dispatch({
        type: queueProcessConstants.CREATE_QUEUE_PROCESS_FAILURE,
        payload: err.response.data,
      });
    });

    if (res.status === 200) {
      dispatch({
        type: queueProcessConstants.CREATE_QUEUE_PROCESS_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};
