import React, { useEffect, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllQueueProcess } from "../../actions";
import "./index.css";
import io from "socket.io-client";
import { api, socketBase, socketPath } from "../../urlConfig";
import { useSpeechSynthesis } from "react-speech-kit";
import { logoSecondary } from "../../urlConfig";
const socket = io(socketBase, {path: socketPath}).connect();

const QueueDisplay = () => {
  const { machineId } = useParams();

  // Room State
  const [room] = useState(machineId);
  // Last Counter State
  const [lastCounter, setLastCounter] = useState("-");
  // Last Queue State
  const [lastQueue, setLastQueue] = useState("");
  // Queues State
  const [queues, setQueues] = useState(null);
  const [socketMessage, setSocketMessage] = useState(null);

  const { speak, voices } = useSpeechSynthesis();
  const pitch = 1;
  const rate = 1;
  const idx = voices.findIndex(
    (opt) => opt.lang === "id-ID" && opt.voiceURI === "Google Bahasa Indonesia"
  );
  const voice = voices[idx];

  const dispatch = useDispatch();

  useEffect(() => {
    socket.emit("join_room", room);
  }, [room]);

  useEffect(() => {
    dispatch(getAllQueueProcess(machineId))
    .then((data) => {
      if (data) {
        setLastCounter(data[0].counter);
        setLastQueue(data[0].queuePrefixNumber);
        const newData = data.map((item) => {
          return {
            counter: item.counter,
            queue: item.queuePrefixNumber
          }
        });
        setQueues(newData);
      }
    })
    .catch((err) => console.log(err));
  }, [dispatch, machineId]);

  useEffect(() => {
    socket.on("receive_message", (socketData) => {
      setSocketMessage(socketData);
      if (socketData?.message === 'next-call') {
        dispatch(getAllQueueProcess(machineId))
        .then((data) => {
          if (data) {
            setLastCounter(data[0].counter);
            setLastQueue(data[0].queuePrefixNumber);
            const newData = data.map((item) => {
              return {
                counter: item.counter,
                queue: item.queuePrefixNumber
              }
            });
            setQueues(newData);
          }
        })
        .catch((err) => console.log(err)); 
      }
    });
  }, [dispatch, machineId]);

  useEffect(() => {
    console.log("MESSAGE", socketMessage);
    if (socketMessage?.text && socketMessage?.text !== "") {
      console.log("TEXT", socketMessage?.text);
      speak({
        text: socketMessage?.text,
        voice,
        rate,
        pitch,
      });
    }
  }, [socketMessage]);

  const renderQueueTable = () => {
    return queues && queues.length > 0 ? (
      queues.map((data, index) => (
        <Row
          className={
            (index + 1) % 2 === 0
              ? "queue-table-body-v2 m-0"
              : "queue-table-body m-0"
          }
          key={index}
        >
          <Col>
            <span className="fs-25">{data?.counter}</span>
          </Col>

          <Col>
            <span className="fs-25">{data?.queue}</span>
          </Col>
        </Row>
      ))
    ) : (
      <Row className="queue-table-body m-0 text-center">
        <Col>
          <span className="fs-25">Belum Ada Antrian</span>
        </Col>
      </Row>
    );
  };

  return (
    <div className="queue-bg">
      <div className="queue-container">
        <Row className="m-0 mb-4">
          <Col className="text-center" style={{display: "flex", gap: "32px"}}>
            <Image src="/img/logo/logo-login-hospita.svg" height={"80px"} />
            { logoSecondary && logoSecondary !== "" && ( 
              <Image src={logoSecondary} height={"80px"} />
            )}
          </Col>
        </Row>
        <Row className="m-0">
          <Col md={5}>
            <Row>
              <Col className="mb-4">
                <div className="queue-card">
                  <span className="fw-bold fs-60 cl-green">
                    {lastCounter}
                  </span>
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="mb-4">
                <div className="queue-card-v2">
                  <div className="queue-card-v2-title">
                    <span className="fw-semi-bold fs-40 cl-white">
                      Nomor Antrian
                    </span>
                  </div>

                  <div className="queue-card-v2-body">
                    <span className="fw-bold fs-60 cl-green">
                      {lastQueue}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

          <Col md={7} className="mb-4">
            <div className="queue-card-v3">
              <Row className="m-0 queue-table-header">
                <Col className="overflow-ellipsis">
                  <span className="fw-semi-bold fs-25">Loket</span>
                </Col>

                <Col className="overflow-ellipsis">
                  <span className="fw-semi-bold fs-25">Nomor Antrian</span>
                </Col>
              </Row>

              <div className="queue-table-row-body">{renderQueueTable()}</div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default QueueDisplay;
