import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../actions";
import Button from "../../components/Button";
import CheckRadio from "../../components/CheckRadio";
import Input from "../../components/Input";
import "./index.css";

const Login = () => {
  const [data, setData] = useState({
    username: "",
    password: "",
  });

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.hasOwnProperty("accessToken")) {
      window.location = "/home";
    }
  }, [auth]);

  const handleLogin = () => {
    let body = {
      username: data.username,
      password: data.password,
    };

    dispatch(login(body)).catch((err) => console.log(err));
  };

  return (
    <div className="login-bg">
      <Container className="login-container">
        <Row>
          <Col lg={5} className="login-col">
            <div className="login-card">
              <Row className="mb-3">
                <Col className="login-form-col">
                  <span className="mb-2 fw-semi-bold">Nama</span>
                  <Input
                    type="text"
                    placeholder="Masukkan Nama"
                    value={data.username}
                    onChange={(e) =>
                      setData({ ...data, username: e.target.value })
                    }
                  />
                </Col>
              </Row>

              <Row className="mb-2">
                <Col className="login-form-col">
                  <span className="mb-2 fw-semi-bold">Kata Sandi</span>
                  <Input
                    password
                    placeholder="Masukkan Kata Sandi"
                    value={data.password}
                    onChange={(e) =>
                      setData({ ...data, password: e.target.value })
                    }
                  />
                </Col>
              </Row>

              <Row className="mb-5">
                <Col className="cl-grey">
                  <CheckRadio
                    type="checkbox"
                    id="remember-me"
                    label="Ingat saya?"
                  />
                </Col>

                <Col className="d-flex justify-content-end">
                  <span className="cl-green txt-underline cursor-pointer">
                    Lupa kata sandi?
                  </span>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Button title="Masuk" onClick={handleLogin} />
                </Col>
              </Row>
            </div>
          </Col>

          <Col className="login-col">
            <div className="d-none d-lg-block">
              <Image src="/img/background/bg-login-front.svg" height={400} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
