import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import {
  AdminDisplay,
  Home,
  Login,
  PatientDisplay,
  PatientLablePrint,
  QueueDisplay,
} from "./pages";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/patient-display/:machineId"
          element={
            <PrivateRoute>
              <PatientDisplay />
            </PrivateRoute>
          }
        />
        <Route
          path="/patient-display/print/:secret"
          element={
            <PrivateRoute>
              <PatientLablePrint />
            </PrivateRoute>
          }
        />
        <Route
          path="/queue-display/:machineId"
          element={
            <PrivateRoute>
              <QueueDisplay />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin-display/:machineId/:counterId"
          element={
            <PrivateRoute>
              <AdminDisplay />
            </PrivateRoute>
          }
        />
        <Route
          path="/home"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />

        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
