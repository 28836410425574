import axios from "../helpers/axios";
import { counterConstants } from "./constants";

export const getAllCounter = () => {
  return async (dispatch) => {
    dispatch({ type: counterConstants.GET_ALL_COUNTER_REQUEST });

    const res = await axios.get("/api/counter").catch((err) => {
      return dispatch({
        type: counterConstants.GET_ALL_COUNTER_FAILURE,
        payload: err.response.data,
      });
    });

    if (res.status === 200) {
      dispatch({
        type: counterConstants.GET_ALL_COUNTER_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};

export const getCounter = (id) => {
  return async (dispatch) => {
    dispatch({ type: counterConstants.GET_ALL_COUNTER_REQUEST });

    const res = await axios.get(`/api/counter/${id}`).catch((err) => {
      return dispatch({
        type: counterConstants.GET_ALL_COUNTER_FAILURE,
        payload: err.response.data,
      });
    });

    if (res.status === 200) {
      dispatch({
        type: counterConstants.GET_ALL_COUNTER_SUCCESS,
        payload: res.data,
      });

      return res.data;
    }
  };
};
