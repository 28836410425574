import React, { useEffect, useMemo, useRef, useState } from "react";
import { Col, Image, Row, ButtonGroup, ButtonToolbar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { createQueueProcess, getQueueMachine } from "../../actions";
import Button from "../../components/Button";
import { encode as base64_encode } from "base-64";
import "./index.css";
import PatientLablePreview from "./Preview";
import { useReactToPrint } from 'react-to-print';
import { logoSecondary } from "../../urlConfig";

const PatientDisplay = () => {
  const { machineId } = useParams();
  let componentRef = useRef(null);
  const [displayCol, setDisplayCol] = useState(1);
  const [displayColBootsrap, setDisplayColBootsrap] = useState(12);
  const [displaySubColBootsrap, setDisplaySubColBootsrap] = useState(8);
  const [displayCol1Data, setDisplayCol1Data] = useState([]);
  const [displayCol2Data, setDisplayCol2Data] = useState([]);
  const [displayCol3Data, setDisplayCol3Data] = useState([]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      console.log("BEFORE");
    },
  });

  const queueProcess = useSelector((state) => state.queueProcess);
  const queueMachine = useSelector((state) => state.queueMachine);
  const [dataEncoded, setDataEncoded] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getQueueMachine(machineId))
      .then((data) => {
        const items = data?.queueItems || [];
        setDisplayCol1Data(items);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dispatch, machineId]);

  useEffect(() => {
    if (queueProcess.createQueueProcess) {
      const encodedData = base64_encode(
        JSON.stringify(queueProcess.createQueueProcess)
      );
      setDataEncoded(encodedData);
      // window.open(`/patient-display/preview/${encodedData}`, "_blank");

    }
  }, [queueProcess.createQueueProcess]);

  const handleDisplay = async (num) => {
    const items = queueMachine?.queueMachine?.queueItems || null;
    setDisplayCol(num);
    if (items) {
      if (num === 1) {
        setDisplayColBootsrap(12);
        setDisplaySubColBootsrap(8);
        setDisplayCol1Data(items);
      }
      if (num === 2) {
        setDisplayColBootsrap(6);
        setDisplaySubColBootsrap(12);
        const diff = Math.ceil(items.length / num);
        const item1 = items.map((item, i) => {
          return i < diff && item
        }).filter(Boolean);
        setDisplayCol1Data(item1);
        const item2 = items.map((item, i) => {
          return i >= diff && item
        }).filter(Boolean);
        setDisplayCol2Data(item2);
      }
      if (num === 3) {
        setDisplayColBootsrap(4);
        setDisplaySubColBootsrap(12);
        const diff = Math.ceil(items.length / num);
        const item1 = items.map((item, i) => {
          return i < diff && item
        }).filter(Boolean);
        setDisplayCol1Data(item1);
        const item2 = items.map((item, i) => {
          return i >= diff && i < (diff + diff) && item
        }).filter(Boolean);
        setDisplayCol2Data(item2);
        const item3 = items.map((item, i) => {
          return i >= (diff + diff) && item
        }).filter(Boolean);
        setDisplayCol3Data(item3);
      }
    }
  }

  const handleCreate = async (itemId) => {
    let body = {
      queueMachine: machineId,
      queueItem: itemId,
    };

    return new Promise((resolve, reject) => {
      dispatch(createQueueProcess(body));
      setTimeout(() => {
        resolve()
      }, 1000);
    });
  };

  const handleCreateAndPrint = (itemId) => {
    handleCreate(itemId).then( () => {
      handlePrint()
    }).catch((error) => {
      console.error("Erorr dispatching: ", error);
    })
  }

  return (
    <div className="patient-bg">
      <div className="patient-header">
        <Row className="m-0 mb-5">
          <Col className="text-center" style={{display: "flex", gap: "32px", alignItems: "center", justifyContent: "center"}}>
            <Image src="/img/logo/logo-login-hospita.svg" height={"80px"} />
            { logoSecondary && logoSecondary !== "" && ( 
              <Image src={logoSecondary} height={"80px"} />
            )}
          </Col>
        </Row>
        <div className="settings"> 
          <ButtonToolbar
            className="justify-content-between"
            aria-label="Toolbar with Button groups"
          >
            <ButtonGroup aria-label="First group">
              <Button variant="secondary" onClick={() => handleDisplay(1)} title={1} />{' '}
              <Button variant="secondary" onClick={() => handleDisplay(2)} title={2} />{' '}
              <Button variant="secondary" onClick={() => handleDisplay(3)} title={3} />
            </ButtonGroup>
          </ButtonToolbar>
        </div>
      </div>
      <div className="patient-container">
        
          <Row>
            {displayCol > 0 && (
              <Col md={displayColBootsrap}>
                {
                  displayCol1Data.map((data, index) => (
                    <Row className="m-0 mb-4 d-flex justify-content-center" key={index}>
                      <Col md={displaySubColBootsrap} className="text-center d-flex justify-content-center">
                        <Button
                          className={(queueMachine.queueMachine?.queueItems?.length < 4 ? "button-control-h-default" : (queueMachine.queueMachine?.queueItems?.length < 5 ? "button-control-h-100" : "button-control-h-80"))}
                          title={data.name}
                          onClick={() => handleCreateAndPrint(data._id)}
                        />
                      </Col>
                    </Row>
                  ))
                }
              </Col>
            )}
            {displayCol > 1 && (
              <Col md={displayColBootsrap}>
                {
                  displayCol2Data.map((data, index) => (
                    <Row className="m-0 mb-4 d-flex justify-content-center" key={index}>
                      <Col md={displaySubColBootsrap} className="text-center d-flex justify-content-center">
                        <Button
                          className={(queueMachine.queueMachine?.queueItems?.length < 4 ? "button-control-h-default" : (queueMachine.queueMachine?.queueItems?.length < 5 ? "button-control-h-100" : "button-control-h-80"))}
                          title={data.name}
                          onClick={() => handleCreateAndPrint(data._id)}
                        />
                      </Col>
                    </Row>
                  ))
                }
              </Col>
            )}
            {displayCol > 2 && (
              <Col md={displayColBootsrap}>
                {
                  displayCol3Data.map((data, index) => (
                    <Row className="m-0 mb-4 d-flex justify-content-center" key={index}>
                      <Col md={displaySubColBootsrap} className="text-center d-flex justify-content-center">
                        <Button
                          className={(queueMachine.queueMachine?.queueItems?.length < 4 ? "button-control-h-default" : (queueMachine.queueMachine?.queueItems?.length < 5 ? "button-control-h-100" : "button-control-h-80"))}
                          title={data.name}
                          onClick={() => handleCreateAndPrint(data._id)}
                        />
                      </Col>
                    </Row>
                  ))
                }
              </Col>
            )}
          </Row>
       
      </div>
      <div>
      <div style={{ display: "none" }}>
        <PatientLablePreview ref={componentRef} data={dataEncoded} />
      </div>
        {/* <button onClick={() => handlePrint()}>Print this out!</button> */}
      </div>
    </div>
  );
};

export default PatientDisplay;
