import React, { useEffect, useState } from "react";
import { Card, Col, Dropdown, Image, Row, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HiArrowSmLeft } from "react-icons/hi";
import Button from "../../components/Button";
import Header from "../../components/Header";
import {
  getCounter,
  getQueueMachine,
  getQueueProcess,
  nextCallQueueProcess,
} from "../../actions";
import Modal from "../../components/Modal";
import "./index.css";
import io from "socket.io-client";
import { api, socketBase, socketPath } from "../../urlConfig";

const socket = io(socketBase, {path: socketPath}).connect();

const AdminDisplay = () => {
  const { machineId, counterId } = useParams();
  const [modal, setModal] = useState(false);

  // Room State
  const [room, setRoom] = useState(null);
  // Machine State
  const [machine, setMachine] = useState(null);
  // Counter State
  const [counter, setCounter] = useState(null);
  // Queue State
  const [queue, setQueue] = useState("-");
   // Queue Report
   const [queueReport, setQueueReport] = useState(null);
  
  const queueProcess = useSelector((state) => state.queueProcess);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    setRoom(machineId);
  }, [machineId]);

  useEffect(() => {
    socket.emit("join_room", room);
  }, [room]);

  useEffect(() => {
    getMachine(machineId).then((data) => {
      setMachine(data);
      setQueueReport(data);
    });
  }, [machineId]);

  useEffect(() => {
    dispatch(getCounter(counterId))
    .then((data) => {
      setCounter(data);
    })
    .catch((err) => console.log(err));
  }, [dispatch, counterId]);

  useEffect(() => {
    dispatch(getQueueProcess(machineId, counterId))
    .then((data) => {
      if (data) {
        setQueue(data.queuePrefixNumber);
      }
    })
    .catch((err) =>
      console.log(err)
    );
    
  }, [counterId, dispatch, machineId]);

  useEffect(() => {
    if (queueProcess.nextError) {
      setModal(true);
    }
  }, [queueProcess.nextError]);

  const sendMessage = (message, text) => {
    socket.emit("send_message", { message, room, text });
  };

  const getMachine = (machineId) => {
    return dispatch(getQueueMachine(machineId))
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
  };

  const renderModal = () => {
    return (
      <Modal
        size="md"
        show={modal}
        handleClose={() => setModal(false)}
        title="Konfirmasi"
      >
        <Row className="m-0 text-center">
          <Col>
            <Image src="/img/icon/icon-timedate.svg" height={"100px"} />
          </Col>
        </Row>

        <Row className="m-0 mb-5 text-center">
          <Col>
            <span>Belum ada antrian</span>
          </Col>
        </Row>

        <Row className="m-0">
          <Col>
            <Button title="Tutup" onClick={() => setModal(false)} />
          </Col>
        </Row>
      </Modal>
    );
  };

  const handleVoiceCall = (queue) => {
    if (queue !== "-" && counter) {
      return `Nomor antrian ${queue}, silahkan menuju ${counter.name}`
    }
    return "";
  }

  const handleNext = (itemId) => {
    let body = {
      queueMachine: machineId,
      queueItem: itemId,
      counter: counterId,
    };

    dispatch(nextCallQueueProcess(body))
      .then((data) => {
        if (data && data !== undefined) {
          const callText = handleVoiceCall(data.queuePrefixNumber);
          sendMessage("next-call", callText);
          setQueue(data.queuePrefixNumber);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        // Call refresh data;
        handleRefresh();
      });;
  };

  const handleRecall = () => {
    const callText = handleVoiceCall(queue);
    sendMessage("re-call", callText);
    
    // Call refresh data;
    handleRefresh();
  };
  
  const handleRefresh = () => {
    getMachine(machineId).then((data) => {
      setQueueReport(data);
    });
  }

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Header />

      <div className="large-container">
        <div className="large-inner-container">
          <Row className="m-0 mb-4">
            <Col>
              <span className="fw-bold fs-20">{machine?.name}</span>
            </Col>
          </Row>

          <Row className="m-0 mb-4">
            <Col>
              <div onClick={handleBack} className="d-flex">
                <HiArrowSmLeft className="fs-30 cl-green cursor-pointer" />
                <div className="fw-bold fs-18 cursor-pointer mt-01 ml-04">Kembali</div>
              </div>
            </Col>
          </Row>

          <Row className="m-0 mb-4">
            <Col>
              <div className="desk-card">
                <span className="fw-bold fs-40 cl-green">
                  {counter?.name}
                </span>
              </div>
            </Col>
          </Row>

          <Row className="m-0 mb-4">
            <Col>
              <div className="desk-card-v2">
                <div className="desk-card-v2-title">
                  <span className="fw-semi-bold fs-25 cl-white">
                    Nomor Antrian
                  </span>
                </div>

                <div className="desk-card-v2-body">
                  <span className="fw-bold fs-40 cl-green">
                    {queue}
                  </span>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="m-0 mb-4">
            <Col>
              <Button
                title="Panggil"
                onClick={() => handleRecall()}
              />
            </Col>
            <Col>
              {machine?.queueItems?.length > 0 ? (
                machine?.queueItems?.length > 1 ? (
                  <Dropdown>
                    <Dropdown.Toggle className="dropdown-control">
                      Selanjutnya
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu-control">
                      {machine?.queueItems.map(
                        (data, index) => (
                          <Dropdown.Item
                            className="dropdown-item-control"
                            key={index}
                            onClick={() => handleNext(data._id)}
                          >
                            {data.name}
                          </Dropdown.Item>
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <Button
                    title="Selanjutnya"
                    onClick={() =>
                      handleNext(machine?.queueItems[0]._id)
                    }
                  />
                )
              ) : null}
            </Col>
          </Row>

          <Row className="m-4 mb-4">
            {/* <Col>
             
            </Col> */}
            <Col md={{ span: 8, offset: 2 }} >
              <Card>
                <Card.Body>
                  <Card.Title>
                    Dashboard - {machine?.name}
                    <Button  size="sm" title="Refresh" onClick={() => handleRefresh()} />
                  </Card.Title>
                  <Card.Text className="mt-3">
                    <Table striped bordered hover size="sm">
                      <thead>
                        <tr>
                          <th width="65" className="text-center">#</th>
                          <th>Antrian</th>
                          <th width="200px" className="text-center">Belum Dipanggil</th>
                          <th width="200px" className="text-center">Sudah Dipanggil</th>
                          <th width="200px" className="text-center">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          queueReport && queueReport.queueItems ? (<>
                            {
                              queueReport.queueItems.map(function(queue, i){
                                const total = queue?.queueReport?.total || 0;
                                const called = queue?.queueReport?.totalCalled || 0;

                                return <tr key={i}> 
                                        <td className="text-center">{ i+1 }</td>
                                        <td >{ queue.name }</td>
                                        <td className="text-center">{ total - called }</td>
                                        <td className="text-center">{ called }</td>
                                        <td className="text-center">{ total }</td>
                                      </tr>;
                              })
                            }
                          </>) : (<>
                            <tr>
                              <td colSpan={5} className="text-center">Antrian Belum Tersedia</td>
                            </tr>
                          </>)
                        }
                      </tbody>
                    </Table>
                  </Card.Text>
                </Card.Body>
              </Card>
              
            </Col>
          </Row>
        </div>
        <div className="text-center mt-2">
          <span>Aido Health All Right Reserved </span>
        </div>
      </div>
      {renderModal()}
    </>
  );
};

export default AdminDisplay;
