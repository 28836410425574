import authReducers from "./auth.reducers";
import queueMachineReducers from "./queueMachine.reducers";
import counterReducers from "./counter.reducers";
import queueProcessReducers from "./queueProcess.reducers";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  auth: authReducers,
  queueMachine: queueMachineReducers,
  counter: counterReducers,
  queueProcess: queueProcessReducers,
});

export default rootReducer;
