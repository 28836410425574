export const authConstants = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
};

export const queueMachineConstants = {
  GET_QUEUE_MACHINE_REQUEST: "GET_QUEUE_MACHINE_REQUEST",
  GET_QUEUE_MACHINE_FAILURE: "GET_QUEUE_MACHINE_FAILURE",
  GET_QUEUE_MACHINE_SUCCESS: "GET_QUEUE_MACHINE_SUCCESS",
  GET_ALL_QUEUE_MACHINE_REQUEST: "GET_ALL_QUEUE_MACHINE_REQUEST",
  GET_ALL_QUEUE_MACHINE_FAILURE: "GET_ALL_QUEUE_MACHINE_FAILURE",
  GET_ALL_QUEUE_MACHINE_SUCCESS: "GET_ALL_QUEUE_MACHINE_SUCCESS",
};

export const counterConstants = {
  GET_COUNTER_REQUEST: "GET_COUNTER_REQUEST",
  GET_COUNTER_FAILURE: "GET_COUNTER_FAILURE",
  GET_COUNTER_SUCCESS: "GET_COUNTER_SUCCESS",
  GET_ALL_COUNTER_REQUEST: "GET_ALL_COUNTER_REQUEST",
  GET_ALL_COUNTER_FAILURE: "GET_ALL_COUNTER_FAILURE",
  GET_ALL_COUNTER_SUCCESS: "GET_ALL_COUNTER_SUCCESS",
};

export const queueProcessConstants = {
  GET_QUEUE_PROCESS_REQUEST: "GET_QUEUE_PROCESS_REQUEST",
  GET_QUEUE_PROCESS_FAILURE: "GET_QUEUE_PROCESS_FAILURE",
  GET_QUEUE_PROCESS_SUCCESS: "GET_QUEUE_PROCESS_SUCCESS",
  GET_ALL_QUEUE_PROCESS_REQUEST: "GET_ALL_QUEUE_PROCESS_REQUEST",
  GET_ALL_QUEUE_PROCESS_FAILURE: "GET_ALL_QUEUE_PROCESS_FAILURE",
  GET_ALL_QUEUE_PROCESS_SUCCESS: "GET_ALL_QUEUE_PROCESS_SUCCESS",
  NEXT_CALL_QUEUE_PROCESS_REQUEST: "NEXT_CALL_QUEUE_PROCESS_REQUEST",
  NEXT_CALL_QUEUE_PROCESS_FAILURE: "NEXT_CALL_QUEUE_PROCESS_FAILURE",
  NEXT_CALL_QUEUE_PROCESS_SUCCESS: "NEXT_CALL_QUEUE_PROCESS_SUCCESS",
  CREATE_QUEUE_PROCESS_REQUEST: "CREATE_QUEUE_PROCESS_REQUEST",
  CREATE_QUEUE_PROCESS_FAILURE: "CREATE_QUEUE_PROCESS_FAILURE",
  CREATE_QUEUE_PROCESS_SUCCESS: "CREATE_QUEUE_PROCESS_SUCCESS",
};
